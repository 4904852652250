import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Danial Panah</h2>
        <p>BACKEND WEB ENGINEER</p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Senior innovation backend developer with +8 years of experience
        in backend developing, adaption and maintenance modern web applications with
        focusing on PHP Laravel Framework based web applications.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/contact') ? <Link to="/contact" className="button">Contact Me</Link> : <Link to="/contact" className="button">Contact</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Danial Panah <Link to="/">danialrp.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
